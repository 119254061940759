export const subAccountAccessValues = {
  // Note that these are strings because a value of 0 is falsy and exposes a
  // bug in the Select component.
  none: '0',
  super: '1',
  privileges: '2'
};

export const subAccountAccessOptions = [
  {
    label: 'User cannot log in to sub accounts',
    value: '0'
  },
  {
    label: 'User can log in to all sub accounts with all privileges',
    value: '1'
  },
  {
    label: 'Specify privileges for office groups',
    value: '2'
  }
];
